import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import {
  ViewTaskReviewComponent
} from "../feature/home-task/mobile-edit-hometask/view-task-history/view-task-review/view-task-review.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCardModule} from "@angular/material/card";
import {MatRadioModule} from "@angular/material/radio";
import {TranslateModule} from "@ngx-translate/core";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatTableModule} from "@angular/material/table";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {FlexModule} from "@angular/flex-layout";
import {
    ViewTaskReviewModal
} from "../feature/home-task/mobile-edit-hometask/view-task-history/view-task-review-modal/view-task-review.modal";
import {MatIconModule} from "@angular/material/icon";
@NgModule({
    declarations: [ViewTaskReviewComponent, ViewTaskReviewModal],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        MatProgressBarModule,
        MatCardModule,
        MatRadioModule,
        TranslateModule,
        MatExpansionModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatIconModule,
        FlexModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgbModule,
        MaterialModule,
        FeatherIconsModule,
        ViewTaskReviewComponent,
        ViewTaskReviewModal
    ],
})
export class SharedModule {}
