import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from "@angular/common/http";
import {DisciplineModel} from "../models/discipline/discipline.model";
import {constructQueryParameters} from "../functions/query-param.function";
import {ServiceCommonConstants} from "../constants/service-common.constants";

@Injectable({
    providedIn: 'root'
})
export class DisciplineService {
    private readonly GENERAL = `${ServiceCommonConstants.BASE_ROAD_MAP_URL}/api/v1/discipline`;
    private readonly GENERAL_TRAINING = `${ServiceCommonConstants.BASE_ROAD_MAP_URL}/api/v1/training-camp`;

    constructor(private httpClient: HttpClient) {
    }

    saveDiscipline(discipline: DisciplineModel): Observable<any> {
        return this.httpClient.post(this.GENERAL, discipline);
    }

    getAllDisciplines(params: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all` + constructQueryParameters(params));
    }

    getAllDisciplinesByCampId(orgId: number) {
        return this.httpClient.get(`${this.GENERAL}/orgId/` + orgId);
    }

    getDisciplineById(disciplineId: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/id/` + disciplineId);
    }

    deleteDisciplineById(disciplineId: any): Observable<any> {
        return this.httpClient.delete(`${this.GENERAL}/delete/id/` + disciplineId);
    }

    getAllDisciplineDayTemplatesById(disciplineDayId: any): Observable<any>{
        return this.httpClient.get(`${this.GENERAL}/disciplineday/${disciplineDayId}/template/all`);
    }

    deleteDisciplineDayTemplateById(templateId: any): Observable<any> {
        return this.httpClient.delete(`${this.GENERAL}/template/delete/id/` + templateId);
    }

    getAllDisciplineDayTemplatesBySubjectId(subjectId: any) {
        return this.httpClient.get(`${this.GENERAL}/template/all/subjectId/` + subjectId);
    }

    getAllDisciplineDayTemplatesBySubjectIdAndTrainingId(subjectId: number, trainingId: number) {
        return this.httpClient.get(`${this.GENERAL}/template/all/subjectId/` + subjectId + `/training/${trainingId}`);
    }

    saveTrainingCamp(trainingCamp: any) {
        return this.httpClient.post(this.GENERAL_TRAINING, trainingCamp);
    }

    getAllTrainingCamps(params: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL_TRAINING}/all` + constructQueryParameters(params));
    }

    getTrainingCampById(trainingCampId: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL_TRAINING}/id/` + trainingCampId);
    }

    deleteTrainingCampById(trainingCampId: any): Observable<any> {
        return this.httpClient.delete(`${this.GENERAL_TRAINING}/delete/id/` + trainingCampId);
    }

    getAllTrainingCampsByDirectionId(directionId: any) {
            return this.httpClient.get(`${this.GENERAL_TRAINING}/direction/` + directionId);
    }

    getDetailedStatsByTaskAndUserId(userId: number, taskId: number): Observable<any>{
        return this.httpClient.get(`crm/task-service/stats/user/${userId}/task/${taskId}/detail`);
    }

    getDetailedStatsByTaskId(taskId: number): Observable<any> {
        return this.httpClient.get(`crm/task-service/stats/task/${taskId}/detail`);
    }
}
