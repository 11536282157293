import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class PersistenceService {

    public static readonly CURRENT_USER = 'currentUser';
    public static readonly CURRENT_LANG = 'lang';
    public static readonly IS_RTL = "isRtl";
    public static readonly URL_KEEPER = 'url-keeper';

    constructor(private storageService: StorageService) {}

    set(key: string, data: any): void {
        try {
            if (environment.secureEnabled) {
                this.storageService.secureStorage.setItem(key, JSON.stringify(data));
            }else {
                localStorage.setItem(key, JSON.stringify(data));
            }
        } catch (e) {
            console.error('Error saving to localStorage', e);
        }
    }

    get(key: string) {
        if (environment.secureEnabled) {
            try {
                const value = this.storageService.secureStorage.getItem(key);
                const obj = (value !== null) ? (JSON.parse(value)) : null;
                return JSON.parse(obj);
            } catch (e) {
                try {
                    return JSON.parse(this.storageService.secureStorage.getItem(key));
                } catch (e) {
                    return this.storageService.secureStorage.getItem(key);
                }
            }
        } else {
            try {
                if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== 'undefined') {
                    const value = localStorage.getItem(key);
                    const obj = (value !== null) ? (JSON.parse(value)) : null;
                    return JSON.parse(obj);
                }
            } catch (e) {
                try {
                    return JSON.parse(localStorage.getItem(key));
                } catch (e) {
                    return localStorage.getItem(key);
                }
            }
        }
    }

    clear(key) {
        try {
            if (environment.secureEnabled) {
                this.storageService.secureStorage.removeItem(key);
            } else {
                localStorage.removeItem(key);
            }
        } catch (e) {
            console.error('Error clearing from localStorage', e);
        }
    }

    getCurrentUser() {
        return this.get(PersistenceService.CURRENT_USER);
    }
}
