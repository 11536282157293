<div class="side-nav-bar-content">
    <ul class="side-nav-bar-menu" *ngIf="currentUser.roles === 'ROLE_TEACHER'">
        <li>
            <a [routerLinkActive]="'activeLink'" routerLink="/template"><i class="material-icons">description</i><p>Үлгі</p></a>
        </li>
        <li>
            <a [routerLinkActive]="'activeLink'" routerLink="/myGroups" ><i class="material-icons">people_outline</i><p>Менің топтарым</p></a>
        </li>
        <li>
            <a [routerLinkActive]="'activeLink'" routerLink="/home-tasks" ><i class="material-icons">inventory_2</i><p>Үй тапсырмалары</p></a>
        </li>
        <li>
            <a [routerLinkActive]="'activeLink'" (click)="logout()"><i class="material-icons">power_settings_new</i><p>Шығу</p></a>
        </li>
    </ul>
    <ul class="side-nav-bar-menu" *ngIf="currentUser.roles === 'ROLE_ADMIN'">
        <li>
            <a [routerLinkActive]="'activeLink'" routerLink="/teachers" ><i class="material-icons">people</i><p>Мұғалімдер</p></a>
        </li>
        <li>
            <a [routerLinkActive]="'activeLink'" routerLink="/groups" ><i class="material-icons">my_location</i><p>Топтар                                                         </p></a>
        </li>
        <li>
            <a [routerLinkActive]="'activeLink'" (click)="logout()"><i class="material-icons">power_settings_new</i><p>Шығу</p></a>
        </li>
    </ul>
</div>
