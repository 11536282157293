// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedService {
    private sidebarVisibilitySubject = new BehaviorSubject<boolean>(true);
    sidebarVisibility$ = this.sidebarVisibilitySubject.asObservable();

    toggleSidebarVisibility(value): void {
        this.sidebarVisibilitySubject.next(value);
    }
}
