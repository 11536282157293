import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public data$ = new BehaviorSubject<any>('');

  constructor() { }

  updateData(value) {
    this.data$.next(value);
  }
}
