import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from "../../../core/service/auth.service";
import {PersistenceService} from "../../../core/service/common/persistence.service";

@Component({
  selector: 'app-side-nav-content',
  templateUrl: './side-nav-content.component.html',
  styleUrls: ['./side-nav-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SideNavContentComponent implements OnInit {
  @Input() taskId: any;
  currentUser;
  @Output() changeSubject = new EventEmitter();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private persistence: PersistenceService) {
  }

  ngOnInit(): void {
    this.currentUser = this.persistence.getCurrentUser();
  }

  emit(subject: any) {
    this.changeSubject.emit(subject);
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(["/authentication/signin"]);
      }
    });
  }
}
