import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {TaskModel} from "../models/task.model";
import {TaskFilteredModel} from "../models/filtered/task-filtered.model";
import {TaskGenDto} from "../models/task-gen.dto";
import {Task} from "../models/task";
import {constructQueryParameters} from "../functions/query-param.function";
import {ServiceCommonConstants} from "../constants/service-common.constants";

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    private readonly GENERAL = `${ServiceCommonConstants.BASE_TASK_URL}/api/v1/task`;

    constructor(private httpClient: HttpClient) {
    }

    getAllTasks(): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all`);
    }

    saveTask(task: TaskModel): Observable<any> {
        return this.httpClient.post(this.GENERAL, task);
    }

    getAllTaskByFilter(params: TaskFilteredModel): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/filter` + constructQueryParameters(params));
    }

    getAllTaskStatus(): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/status/list`);
    }

    getActiveTaskByClientId(): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/active`);
    }

    getActiveTaskByClientIdV2(clientId: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/active/clientId/${clientId}`);
    }
    generateTask(taskGenDto: TaskGenDto) {
        return this.httpClient.post(`${this.GENERAL}/generate`, taskGenDto);
    }


    getTaskResultListByClientId(clientId: number, page: number): Observable<Task> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("page", page);
        return this.httpClient.get<Task>(`${this.GENERAL}/results/client/${clientId}`, {params: queryParams});
    }

    getTotalAmountTasksByClientId(clientId: number) {
        return this.httpClient.get(`${this.GENERAL}/results/total/client/${clientId}`);
    }

    getTaskById(taskId: number): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/id/${taskId}`);
    }

    calculateRemainingActualTime(taskId: number) {
        return this.httpClient.get(`${this.GENERAL}/calculate/${taskId}`);

    }

    getTaskResultBySubjects(taskId: number) {
        return this.httpClient.get(`${this.GENERAL}/${taskId}/calculator/subjects`);
    }

    getTaskResultReview(taskId: number) {
        return this.httpClient.get(`${this.GENERAL}/${taskId}/result/preview`);
    }

    getChart(param): Observable<any> {
        return this.httpClient.put(`${ServiceCommonConstants.BASE_TASK_URL}/api/chart`, param);
    }


    getDetailedStatsByTaskAndUserId(taskId: number): Observable<any>{
        // Default used current user id. Need to use another endpoint if you want to use another user id.
        return this.httpClient.get(`${ServiceCommonConstants.BASE_TASK_URL}/stats/task/${taskId}/detail`);
    }

    getTaskResultByDisciplineTemplateId(clientId: any, templateId: any, page: number, pageSize: number): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("page", page);
        queryParams = queryParams.append("size", pageSize);
        return this.httpClient.get(`${this.GENERAL}/results/client/${clientId}/${templateId}`, {params : queryParams});
    }

    finishTask(id: number) {
        return this.httpClient.post(`${this.GENERAL}/finish/taskId/${id}`, null);
    }
}
