import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {OrganizationFilteredModel} from "../models/filtered/organization-filtered.model";
import {Organization} from "../models/organization.model";
import {constructQueryParameters} from "../functions/query-param.function";
import {ServiceCommonConstants} from "../constants/service-common.constants";

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    private readonly GENERAL = `${ServiceCommonConstants.BASE_QUESTIONNAIRE_URL}/api/v1/school`;

    constructor(private httpClient: HttpClient) {
    }

    getAllOrganization(): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all`);
    }

    getOrganizationById(id: number): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/id/${id}`);
    }

    getAllOrganizationByCity(city: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all/by/city/` + city);
    }

    getAllOrganizationByCityCode(cityCode: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all/by/cityCode/` + cityCode);
    }

    getAllOrganizationByKato(katoList: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/kato/${katoList}`);
    }

    getAllByType(type: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all/by/type/` + type);
    }

    saveOrganization(data: Organization): Observable<any> {
        return this.httpClient.post(this.GENERAL, data);
    }

    deleteOrganization(id: any): Observable<any> {
        return this.httpClient.delete(`${this.GENERAL}/id/` + id);
    }

    getOrganization(id: any): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/id/` + id);
    }

    getAllOrganizationByFilter(params: OrganizationFilteredModel): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/filter` + constructQueryParameters(params));
    }

    getAllOrganizationByFilterAndType(params: OrganizationFilteredModel, type: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/filter/by/type/` + type + constructQueryParameters(params));
    }

    getOrganizationCityList(): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/city/list/`);
    }

    getSchoolInfo(schoolId: number): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/${schoolId}/info/`);
    }

    getAllByAreaCode(areaCode: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all/by/cityCode/${areaCode}`);
    }

    getAllByTypeAndCity(type: string, city: string): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/all/by/type/${type}/city/${city}`);
    }
}
