<nav #navbar class="navbar active shadow-sm"  *ngIf="showMenu">
  <button (click)="toggleSideNav()" mat-stroked-button *ngIf="isMobile">
    <mat-icon style="color: white">menu_open</mat-icon>
  </button>
    <div *ngIf="isMobile" class="container-fluid" style="margin-top: -50px">
        <ul class="nav navbar-nav navbar-right">
            <li class="nav-item user_profile" ngbDropdown>
                <div class="chip dropdown-toggle" ngbDropdownToggle>
                    <span style="color: white">{{userNameToDisplay}}</span>
                </div>
                <p class="user-role"  style="color: white">{{userRoleToDisplay}}</p>
            </li>
        </ul>
    </div>
  <app-side-nav
          [sidenavTemplateRef]="navContent"
          [direction]="'left'"
          [navWidth]="280"
          [duration]="0.5"></app-side-nav>
  <ng-template #navContent>
    <app-side-nav-content>
    </app-side-nav-content>
  </ng-template>
  <div class="container-fluid">
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="float-start mt-2">
        <li style="display: flex; flex-direction: row">
          <button id="hide-menu-btn" mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <i class="material-icons-outlined icon-color">
              menu
            </i>
          </button>
            <h4 *ngIf="this.userRoleToDisplay === 'Админ'" [matTooltip]="this.schoolInfo?.name">{{ truncateText(this.schoolInfo?.name) }}</h4>

        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
<!--         Full Screen Button-->
<!--        <li class="fullscreen">-->
<!--          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">-->
<!--            <i class="material-icons-outlined icon-color">-->
<!--              fullscreen-->
<!--            </i>-->
<!--          </button>-->
<!--        </li>-->
<!--&lt;!&ndash;         #END# Full Screen Button &ndash;&gt;-->
<!--        <li class="nav-item" ngbDropdown>-->
<!--          <a ngbDropdownToggle class="lang-dropdown">-->
<!--&lt;!&ndash;            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16">&ndash;&gt;-->
<!--&lt;!&ndash;            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="16">&ndash;&gt;-->
<!--            <p *ngIf="selectedLanguage !== undefined">{{selectedLanguage}}</p>-->
<!--            <p *ngIf="selectedLanguage === undefined"> {{ defaultLang }}</p>-->
<!--          </a>-->
<!--          <div ngbDropdownMenu class="dropdown-menu lang-item dropdown-menu-right pullDown">-->
<!--            <a href="javascript:void(0);" class="dropdown-item lang-item-list" *ngFor="let item of listLang"-->
<!--               (click)="setLanguage(item.text, item.lang, item.flag)"-->
<!--               [ngClass]="{'active': langStoreValue === item.lang}">-->
<!--&lt;!&ndash;              <img src="{{item.flag}}" class="flag-img" height="12"> <span class="align-middle">&ndash;&gt;-->
<!--              {{ item.text }}-->
<!--&lt;!&ndash;              </span>&ndash;&gt;-->
<!--            </a>-->
<!--          </div>-->
<!--        </li>-->

        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle>
            <span>{{userNameToDisplay}}</span>
          </div>
          <p class="user-role">{{userRoleToDisplay}}</p>
<!--          <div ngbDropdownMenu class="notification-dropdown pullDown">-->
<!--            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>-->
<!--              <ul class="menu">-->
<!--                <li class="body">-->
<!--                  <ul class="user_dw_menu">-->
<!--                    <li>-->
<!--                      <a href="javascript:void(0);" (click)="logout()">-->
<!--                        <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout-->
<!--                      </a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
