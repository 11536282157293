import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import {User} from "../models/user";
import {environment} from "src/environments/environment";
import {ServiceBaseUrlConstants} from "./constant/service-base-url.constants";
import {PersistenceService} from "./common/persistence.service";
import {LSConstants} from "./constant/local-storage.constants";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient,
                private persistence: PersistenceService) {
        this.currentUserSubject = new BehaviorSubject<User>(
            persistence.getCurrentUser()
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string) {
        return this.http
            .post<any>(`${ServiceBaseUrlConstants.authService}/login`, {
                username,
                password,
            })
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.persistence.set(PersistenceService.CURRENT_USER, user);
                    this.currentUserSubject.next(user);
                    return user;
                })
            );
    }

    logout() {
        // remove user from local storage to log user out
        this.persistence.clear(PersistenceService.CURRENT_USER);
        this.currentUserSubject.next(null);
        return of({success: false});
    }

    resetAll() {
        this.persistence.set(LSConstants.HEADER_AUTH, null);
        this.persistence.set(LSConstants.HEADER_USER, null);
    }
}
