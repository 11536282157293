import {NgModule} from "@angular/core";
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {ErrorInterceptor} from "./core/interceptor/error.interceptor";
import {JwtInterceptor} from "./core/interceptor/jwt.interceptor";
import {LocationStrategy, HashLocationStrategy, DatePipe} from "@angular/common";
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import {TranslateModule, TranslateLoader} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ClickOutsideModule} from "ng-click-outside";
import {
    HttpClientModule,
    HTTP_INTERCEPTORS,
    HttpClient,
} from "@angular/common/http";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {HeaderComponent} from "./feature/layout/header/header.component";
import {RightSidebarComponent} from "./feature/layout/right-sidebar/right-sidebar.component";
import {MainLayoutComponent} from "./feature/layout/app-layout/main-layout/main-layout.component";
import {SidebarComponent} from "./feature/layout/sidebar/sidebar.component";
import {PageLoaderComponent} from "./feature/layout/page-loader/page-loader.component";
import {AuthLayoutComponent} from "./feature/layout/app-layout/auth-layout/auth-layout.component";
import {SideNavComponent} from "./feature/layout/side-nav/side-nav.component";
import {SideNavContentComponent} from "./feature/layout/side-nav-content/side-nav-content.component";
import {NgxEchartsModule} from "ngx-echarts";
import {SpinnerComponent} from "./shared/components/spinner/spinner.component";
import {SpinnerInterceptor} from "./core/interceptor/spinner.interceptor";
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient): any {
    return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        PageLoaderComponent,
        SidebarComponent,
        RightSidebarComponent,
        AuthLayoutComponent,
        MainLayoutComponent,
        SideNavComponent,
        SideNavContentComponent,
        SpinnerComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        PerfectScrollbarModule,
        ClickOutsideModule,
        LoadingBarRouterModule,
        MatProgressSpinnerModule,
        // core & shared
        CoreModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: false
        }),
        NgxEchartsModule,
        NgxEchartsModule.forRoot({
            echarts: () => import("echarts")
        }),
    ],
    providers: [
        DatePipe,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true},
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: RequestInterceptor,
        //     multi: true
        // },
    ],
    exports: [
        SideNavComponent,
        SideNavContentComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
