import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {PersistenceService} from "./common/persistence.service";

@Injectable({
    providedIn: "root",
})
export class LanguageService {
    public languages: string[] = ["en", "kz"];

    constructor(public translate: TranslateService,
                private persistence: PersistenceService) {
        let browserLang;
        translate.addLangs(this.languages);

        if (this.persistence.get(PersistenceService.CURRENT_LANG)) {
            browserLang = this.persistence.get(PersistenceService.CURRENT_LANG);
        } else {
            browserLang = translate.getBrowserLang();
        }
        translate.use(browserLang.match(/en|kz/) ? browserLang : "kz");
    }

    public setLanguage(lang) {
        this.translate.use(lang);
        this.persistence.set(PersistenceService.CURRENT_LANG, lang);
    }
}
