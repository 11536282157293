import {Router, NavigationEnd} from "@angular/router";
import {DOCUMENT} from "@angular/common";
import {
    Component,
    Inject,
    ElementRef,
    OnInit,
    Renderer2,
    HostListener,
    OnDestroy,
} from "@angular/core";
import {ROUTES} from "./sidebar-items";
import {AuthService} from "src/app/core/service/auth.service";
import {SharedService} from "../../../core/service/shared.service";
import {RouteInfo} from "./sidebar.metadata";
import {DataService} from "../../../core/service/data.service";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.sass"],
})
export class SidebarComponent implements OnInit, OnDestroy {
    public sidebarItems: RouteInfo[];
    public innerHeight: any;
    public bodyTag: any;
    listMaxHeight: string;
    listMaxWidth: string;
    userFullName: string;
    userImg: string;
    headerHeight = 60;
    routerObj = null;
    showMenu = true;
    showToggleIcon = true;
    toogleMenu: any;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        public elementRef: ElementRef,
        private authService: AuthService,
        private router: Router,
        private sharedService: SharedService,
        private dataService: DataService,
    ) {
        this.renderer.addClass(this.document.body, "theme-" + 'black');
        localStorage.setItem("choose_skin", "theme-" + 'black');
        localStorage.setItem("choose_skin_active", 'black');
        this.renderer.removeClass(this.document.body, "menu_light");
        this.renderer.addClass(this.document.body, "menu_dark");
        this.renderer.removeClass(this.document.body, "logo-white");
        this.renderer.addClass(this.document.body, "logo-black");
        const menuOption = "menu_dark";
        localStorage.setItem("choose_logoheader", "logo-black");
        localStorage.setItem("menuOption", menuOption);
        this.routerObj = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // logic for select active menu in dropdown
                // close sidebar on mobile screen after menu select
                this.renderer.removeClass(this.document.body, "overlay-open");
            }
        });
        this.sharedService.sidebarVisibility$.subscribe((visibility) => {
            if (visibility) {
                this.showMenu = false;
            }
        });
        this.dataService.data$.subscribe((data) => {
            if (data !== '') {
                this.showToggleIcon = data;
                this.toogleMenu = data;
            }
        });
    }

    @HostListener("window:resize", ["$event"])
    windowResizecall(event) {
        this.setMenuHeight();
        this.checkStatuForResize(false);
    }

    @HostListener("document:mousedown", ["$event"])
    onGlobalClick(event): void {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.renderer.removeClass(this.document.body, "overlay-open");
        }
    }

    ngOnInit() {
        if (this.authService.currentUserValue) {
            const userRole = this.authService.currentUserValue.roles;

            this.userFullName = this.authService.currentUserValue.firstName + " " + this.authService.currentUserValue.lastName;
            this.userImg = this.authService.currentUserValue.img;

            this.sidebarItems = this.filterRoutesByRole(ROUTES, userRole)
                .filter(route => route.submenu.length > 0);
            this.initializeSubMenuState(this.sidebarItems);
            if (userRole !== 'ROLE_SUPER_ADMIN') {
                this.sidebarItems = this.sidebarItems.reduce((acc, route) => {
                    if (route.role.includes("ROLE_SUPER_ADMIN")) {
                        return [...acc, route];
                    } else {
                        return [...acc, ...route.submenu];
                    }
                }, []);
            }
        }

        this.initLeftSidebar();
        this.bodyTag = this.document.body;
    }

    ngOnDestroy() {
        this.routerObj.unsubscribe();
    }

    initLeftSidebar() {
        const _this = this;
        // Set menu height
        _this.setMenuHeight();
        _this.checkStatuForResize(true);
    }

    setMenuHeight() {
        this.innerHeight = window.innerHeight;
        const height = this.innerHeight - this.headerHeight;
        this.listMaxHeight = height + "";
        this.listMaxWidth = "500px";
    }

    isOpen() {
        return this.bodyTag.classList.contains("overlay-open");
    }

    checkStatuForResize(firstTime) {
        if (window.innerWidth < 1170) {
            this.renderer.addClass(this.document.body, "ls-closed");
        } else {
            this.renderer.removeClass(this.document.body, "ls-closed");
        }
    }

    mouseHover(e) {
        if (this.toogleMenu !== undefined && this.toogleMenu === false) {
            const body = this.elementRef.nativeElement.closest("body");
            if (body.classList.contains("submenu-closed")) {
                // this.renderer.addClass(this.document.body, "side-closed-hover");
                this.renderer.removeClass(this.document.body, "side-closed");
                this.renderer.removeClass(this.document.body, "submenu-closed");
                this.showToggleIcon = true;
            }
        }
    }

    mouseOut(e) {
        if (this.toogleMenu !== undefined && this.toogleMenu === false) {
            const body = this.elementRef.nativeElement.closest("body");
            if (!body.classList.contains("side-closed")) {
                this.renderer.addClass(this.document.body, "side-closed");
                this.renderer.addClass(this.document.body, "submenu-closed");
                this.showToggleIcon = false;
            }
        }
    }

    logout() {
        this.authService.logout().subscribe((res) => {
            if (!res.success) {
                this.router.navigate(["/authentication/signin"]);
            }
        });
    }

    initializeSubMenuState(menuItems: RouteInfo[]) {
        for (const item of menuItems) {
            if (item.groupTitle) {
                item.showSubMenu = false; // Set the initial state for groupTitle items
                this.initializeSubMenuState(item.submenu); // Recursively initialize for submenus
            }
        }
    }

    filterRoutesByRole(routes, userRole) {
        return routes.map(route => {
            let filteredSubmenu = [];
            if (route.submenu && route.submenu.length > 0) {
                filteredSubmenu = this.filterRoutesByRole(route.submenu, userRole);
            }

            return {
                ...route,
                submenu: filteredSubmenu,
            };
        }).filter(route => route.role.length === 0 || route.role.includes(userRole) || route.submenu.length > 0);
    }

    toggleMenu(menuItem: RouteInfo) {
        if (menuItem.groupTitle) {
            menuItem.showSubMenu = !menuItem.showSubMenu;
        }
    }
}
