import {NgModule} from "@angular/core";
import {Routes, RouterModule} from "@angular/router";
import {Page404Component} from "./feature/authentication/page404/page404.component";
import {AuthGuard} from "./core/guard/auth.guard";
import {MainLayoutComponent} from "./feature/layout/app-layout/main-layout/main-layout.component";
import {AuthLayoutComponent} from "./feature/layout/app-layout/auth-layout/auth-layout.component";
import {
    ViewTaskReviewModal
} from "./feature/home-task/mobile-edit-hometask/view-task-history/view-task-review-modal/view-task-review.modal";

const routes: Routes = [
    {
        path: "",
        component: MainLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "", redirectTo: "/authentication/signin", pathMatch: "full"
            },
            {
                path: "test-review/:taskId",
                component: ViewTaskReviewModal
            },
            {
                path: "landing",
                loadChildren: () =>
                    import("./feature/landing/landing.module").then((m) => m.LandingModule),
            },
            {
                path: "subscribe",
                loadChildren: () =>
                    import("./feature/subscribe/subscribe.module").then((m) => m.SubscribeModule),
            },
            {
                path: "subject",
                loadChildren: () =>
                    import("./feature/ng-subject/ng-subject.module").then((m) => m.NgSubjectModule),
            },
            {
                path: "question-type",
                loadChildren: () =>
                    import("./feature/question-type/question-type.module").then((m) => m.QuestionTypeModule),
            },
            {
                path: "question",
                loadChildren: () =>
                    import("./feature/question/question.module").then((m) => m.QuestionModule),
            },
            {
                path: "question-resource",
                loadChildren: () =>
                    import("./feature/question-resource/question-resource.module").then((m) => m.QuestionResourceModule),
            },
            {
                path: "question-generate",
                loadChildren: () =>
                    import("./feature/question-generate/question-generate.module").then((m) => m.QuestionGenerateModule),
            },
            {
                path: "aim",
                loadChildren: () =>
                    import("./feature/aim/aim.module").then((m) => m.AimModule),
            },
            {
                path: "application",
                loadChildren: () =>
                    import("./feature/application/application.module").then((m) => m.ApplicationModule),
            },
            {
                path: "template",
                loadChildren: () =>
                    import("./feature/core-template/core-template.module").then((m) => m.CoreTemplateModule),
            },
            {
                path: "template-type",
                loadChildren: () =>
                    import("./feature/core-template-type/core-template-type.module").then((m) => m.CoreTemplateTypeModule),
            },
            {
                path: "task",
                loadChildren: () =>
                    import("./feature/task/task.module").then((m) => m.TaskModule),
            },
            {
                path: "organization",
                loadChildren: () =>
                    import("./feature/organization/organization.module").then((m) => m.OrganizationModule),
            },
            {
                path: "school",
                loadChildren: () =>
                    import("./feature/school/school.module").then((m) => m.SchoolModule),
            },
            {
                path: "season",
                loadChildren: () =>
                    import("./feature/season/season.module").then((m) => m.SeasonModule),
            },
            {
                path: "contacts",
                loadChildren: () =>
                    import("../@cliniva/contacts/contacts.module").then((m) => m.ContactsModule),
            },
            {
                path: "dashboard",
                loadChildren: () =>
                    import("./feature/dashboard/dashboard.module").then((m) => m.DashboardModule),
            },
            {
                path: "news",
                loadChildren: () =>
                    import("./feature/news/news.module").then((m) => m.NewsModule),
            },
            {
                path: "analytics",
                loadChildren: () =>
                    import("./feature/analytics/analytics.module").then((m) => m.AnalyticsModule),
            },
            {
                path: "exam-schedule",
                loadChildren: () =>
                    import("./feature/exam-scheduler/exam-scheduler.module").then((m) => m.ExamSchedulerModule),
            },
            {
                path: "syllabus",
                loadChildren: () =>
                    import("./feature/syllabus/syllabus.module").then((m) => m.SyllabusModule),
            },
            {
                path: "custom-registration",
                loadChildren: () =>
                    import("./feature/custom-registration/custom-registration.module").then((m) => m.CustomRegistrationModule),
            },
            {
                path: "statistics",
                loadChildren: () =>
                    import("./feature/statistics/statistics.module").then((m) => m.StatisticsModule),
            },
            {
                path: "report",
                loadChildren: () =>
                    import("./feature/report/report.module").then((m) => m.ReportModule),
            },
            {
                path: "teachers",
                loadChildren: () =>
                    import("./feature/teachers/teachers.module").then((m) => m.TeachersModule),
            },
            {
                path: "groups",
                loadChildren: () =>
                    import("./feature/group/group.module").then((m) => m.GroupModule),
            },
            {
                path: "myGroups",
                loadChildren: () =>
                    import("./feature/mygroup/my-group.module").then((m) => m.MyGroupModule),
            },
            {
                path: "users",
                loadChildren: () =>
                    import("./feature/user/user.module").then((m) => m.UserModule),
            },
            {
                path: "home-tasks",
                loadChildren: () =>
                    import("./feature/home-task/home-task.module").then((m) => m.HomeTaskModule),
            },
            {
                path: "products",
                loadChildren: () =>
                    import("./feature/product/product.module").then((m) => m.ProductModule),
            },
            {
                path: "promo",
                loadChildren: () =>
                    import("./feature/promo-code/promo-code.module").then((m) => m.PromoCodeModule),
            },
            {
                path: "faq",
                loadChildren: () =>
                    import("./feature/faq-module/faq.module").then((m) => m.FaqModule),
            },
            {
                path: "test",
                loadChildren: () =>
                    import("./feature/test/test.module").then((m) => m.TestModule),
            },
            {
                path: "excel-users",
                loadChildren: () =>
                    import("./feature/excel-users/excel-users.module").then((m) => m.ExcelUsersModule),
            },
            {
                path: "stream",
                loadChildren: () =>
                    import("./feature/stream/stream.module").then((m) => m.StreamModule),
            },
            {
                path: "school-theme",
                loadChildren: () =>
                    import("./feature/school-theme/school-theme.module").then((m) => m.SchoolThemeModule),
            },
            {
                path: "survey-question",
                loadChildren: () =>
                    import("./feature/survey-question-module/survey-question.module").then((m) => m.SurveyQuestionModule),
            },
            {
                path: "partner-logo",
                loadChildren: () =>
                    import("./feature/partner-logo/partner-logo.module").then((m) => m.PartnerLogoModule),
            }
        ],
    },
    {
        path: "authentication",
        component: AuthLayoutComponent,
        loadChildren: () =>
            import("./feature/authentication/authentication.module").then(
                (m) => m.AuthenticationModule
            ),
    },
    {path: "**", component: Page404Component},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: "legacy"})],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
