import {AfterContentChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {ViewportScroller} from "@angular/common";
import {DisciplineService} from "../../../../../core/service/discipline.service";
import {TaskService} from "../../../../../core/service/task.service";
import {PersistenceService} from "../../../../../core/service/common/persistence.service";
import {SharedService} from "../../../../../core/service/shared.service";

@Component({
    selector: 'app-view-task-review',
    templateUrl: './view-task-review.modal.html',
    styleUrls: ['./view-task-review.modal.sass']
})
export class ViewTaskReviewModal implements OnInit, AfterContentChecked {

    private taskId: any;
    loading = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    taskResultReview: any = {
        subjects: []
    };
    private currentUser: any;
    pointsEarned: number;
    pointsTotal: number;
    task: any;
    now: Date;
    taskDate: Date;
    mobileMedia: any = window.matchMedia("(max-width:797px)");
    isMobile = false;
    @ViewChild('modalContent', { static: true }) modalContent: ElementRef;

    constructor(private route: ActivatedRoute,
                private scroller: ViewportScroller,
                public router: Router,
                private taskService: TaskService,
                private disciplineService: DisciplineService,
                private persistence: PersistenceService,
                private sharedService: SharedService) {
        this.taskId = this.route.snapshot.paramMap.get('taskId');
        this.sharedService.toggleSidebarVisibility(true);
        if (this.mobileMedia.matches) {
            this.isMobile = true;
        }
    }

  ngOnInit(): void {
    this.loading = true;
    this.calculateTaskResults();
    this.currentUser = this.persistence.getCurrentUser();
    this.now = new Date();
    this.getTaskById(this.taskId);
  }

    ngAfterContentChecked(): void {
        if (this.isMobile) {
            this.change();
        }
    }

    goDown1(question) {
        this.scroller.scrollToAnchor(question);
    }

    calculateTaskResults(){
      this.taskService.getTaskResultReview(this.taskId).subscribe(res => {
        if (res) {
          this.taskResultReview = res;
            console.log(this.taskResultReview)
            for (let i = 0; i < this.taskResultReview.subjects.length; i++) {
                const s = this.taskResultReview.subjects[i];
                for (let j = 0; j < s.questions.length; j++) {
                    const q = s.questions[j];
                    if (q.qcontent) {
                        this.taskResultReview.subjects[i].questions[j].qcontent = q.qcontent.replace(/&nbsp;/g, ' ');
                    }
                    if (q.qmcontent) {
                        this.taskResultReview.subjects[i].questions[j].qmcontent = q.qmcontent.replace(/&nbsp;/g, ' ');
                    }
                }
            }
            console.log(this.taskResultReview.subjects)
            this.getClientAnalytics(this.taskResultReview.subjects);
          this.taskDate = new Date(this.taskResultReview.subjects[0]?.taskDate);
          this.calculatePoints();
          this.loading = false;
        }
      });
    }

    change() {
        const heyElement = document.getElementById("hey");
        if (heyElement != null) {
            const imgElements = heyElement.getElementsByTagName("img");
            if (imgElements != null) {
                for (let i = 0; i < imgElements.length; i++) {
                    if (imgElements[i] != null) {
                        imgElements[i].style.maxWidth = "100%";
                        imgElements[i].style.maxHeight = "150px";
                    }
                }
            }
        }
    }

    getTaskById(taskId: number) {
        this.taskService.getTaskById(taskId).subscribe(res => {
            if (res) {
                this.task = res;
            }
        });
    }

    getColor(choice) {
        if (choice.correctness) {
            return '2px solid #39a616';
        }
    }

    goto(section) {
        this.scroller.scrollToAnchor(section);
    }

    private calculatePoints() {
        this.pointsTotal = 0;
        this.pointsEarned = 0;
        this.taskResultReview.subjects.forEach(subject => {
            this.pointsTotal = this.pointsTotal + subject.pointsTotal;
            this.pointsEarned = this.pointsEarned + subject.pointsEarned;
            this.loading = false;
        });
    }

    goToBack() {
        window.history.back();
    }
    getClientAnalytics(subjects) {
        this.disciplineService.getDetailedStatsByTaskId(this.taskId).subscribe(statistics => {
            if (statistics) {
                subjects.forEach(subject => {
                    subject.statistics = [];
                    statistics.forEach(statistic => {
                        if (subject.subjectId === statistic.subjectId) {
                            subject.statistics.push(statistic);
                        }
                    });
                });
            }
            this.taskResultReview.subjects = subjects;
        });
    }

  printResult() {
    window.print();
  }
}
