<div *ngIf="showMenu">
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ul class="list"
          [ngStyle]="{'position': 'relative', 'max-height': listMaxHeight + 'px',
                    'max-width': listMaxWidth + 'px', 'padding-bottom': 40 + 'px'}"
          [perfectScrollbar]>
        <ng-container *ngFor="let menuItem of sidebarItems">
          <li *ngIf="!menuItem.groupTitle" [ngClass]="menuItem.class">
            <a [routerLink]="[menuItem.path]" class="menu-top">
              <em [ngClass]="menuItem.iconType">{{ menuItem.icon }}</em>
              <span class="hide-menu">{{ menuItem.title | translate }}</span>
            </a>
          </li>
          <li *ngIf="menuItem.groupTitle" [ngClass]="menuItem.class">
            <a [routerLink]="null" class="menu-top" (click)="toggleMenu(menuItem)">
              <em [ngClass]="menuItem.iconType">{{ menuItem.icon }}</em>
              <span class="hide-menu">{{ menuItem.title | translate }}</span>
              <em class="toggle-icon material-icons-two-tone" *ngIf="showToggleIcon"
                 style="position: absolute; right: 0"
                 [ngClass]="{'minus-icon': menuItem.showSubMenu, 'add-icon': !menuItem.showSubMenu}">
                {{ menuItem.showSubMenu ? 'remove' : 'add' }}
              </em>
            </a>

            <ul *ngIf="menuItem.showSubMenu">
              <ng-container *ngFor="let subItem of menuItem.submenu">
                <li *ngIf="!subItem.groupTitle" [ngClass]="subItem.class">
                  <a [routerLink]="[subItem.path]" class="menu-top" style="margin-left: 20px">
                    <em [ngClass]="subItem.iconType">{{ subItem.icon }}</em>
                    <span class="hide-menu">{{ subItem.title | translate }}</span>
                  </a>
                </li>
                <li *ngIf="subItem.groupTitle"  [ngClass]="subItem.class">
                  <a [routerLink]="null" class="menu-top" style="margin-left: 20px" (click)="toggleMenu(subItem)">
                    <em [ngClass]="subItem.iconType">{{ subItem.icon }}</em>
                    <span class="hide-menu">{{ subItem.title | translate }}</span>
                    <em class="toggle-icon material-icons-two-tone" *ngIf="showToggleIcon"
                        style="position: absolute; right: 0"
                       [ngClass]="{'minus-icon': subItem.showSubMenu, 'add-icon': !subItem.showSubMenu}">
                      {{ subItem.showSubMenu ? 'remove' : 'add' }}
                    </em>
                  </a>
                  <ul *ngIf="subItem.showSubMenu">
                    <li *ngFor="let thirdLevelItem of subItem.submenu" [ngClass]="thirdLevelItem.class">
                      <a [routerLink]="[thirdLevelItem.path]" class="menu-top"  style="margin-left: 40px" >
                        <em [ngClass]="thirdLevelItem.iconType">{{ thirdLevelItem.icon }}</em>
                        <span class="hide-menu">{{ thirdLevelItem.title | translate }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
        <li class="pb-12">
          <a (click)="logout()" class="menu-top">
            <em class="material-icons-two-tone">power_settings_new</em>
            <span class="hide-menu">{{ 'Logout' | translate }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
