import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {finalize} from 'rxjs/operators';
import {Subscription} from "rxjs";
import {SpinnerOverlayService} from "../service/spinner.service";

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {


    constructor(private readonly spinnerOverlayService: SpinnerOverlayService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
        return next.handle(req).pipe(finalize(() => spinnerSubscription.unsubscribe()));
    }

}
