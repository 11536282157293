<section class="content">
    <div class="content-block" >
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="body">
                        <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" >
                            <mat-spinner style="margin-top: 30vh"></mat-spinner>
                        </div>
                        <div class="col" *ngIf="!loading">
                            <div class="row block">
                                <div style="top: 30px" id="mainHeader">
                                    <mat-card class="block-buttons">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <div class="row">
                                                    <div class="col-md-12" style="text-align: start; font-family: Montserrat-SemiBold, sans-serif" class="user-info">
                                                        <h2 style="color: #3f51b5!important">{{task.user.surname}} {{task.user.name}}</h2>
                                                        <h4>{{task.template.direction.name}}</h4>
                                                    </div>
                                                    <div class="col-md-12 dates">
                                                        <mat-card-subtitle>{{taskDate.toLocaleString()}}</mat-card-subtitle>
                                                    </div>
                                                    <div class="col-md-6 header1">
                                                        <h5
                                                                style="font-weight: bold; font-family: Montserrat-Bold; font-size: 30px">{{taskResultReview.subjects[0]?.templateName}}</h5>
                                                    </div>
                                                    <div class="col-md-6 header">
                                                        <h5 style="font-weight: bold; font-family: Montserrat-Bold; font-size: 30px">{{pointsEarned}}
                                                            /{{pointsTotal}}</h5>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <mat-progress-bar mode="determinate" [value]="100*pointsEarned/pointsTotal"></mat-progress-bar>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 result">
                                                <button mat-raised-button color="primary"  *ngIf="!isMobile" (click)="printResult()"
                                                        style="width: 170px; margin:auto; border-radius: 10px; background-color: #3f51b5; padding: 10px 20px; border: none; color: white">{{'Print test result' | translate}}
                                                </button>
                                                <button mat-raised-button color="primary" (click)="goToBack()"
                                                        style="width: 170px; margin:auto; border-radius: 10px; background-color: #3f51b5; padding: 10px 20px; border: none; color: white">{{'Back' | translate}}
                                                </button>
                                            </div>
                                            <div class="col col col-lg-4" *ngIf="isMobile">
                                                <div class="sticked-menu">
                                                    <mat-card class="block-buttons">
                                                        <mat-accordion>
                                                            <mat-expansion-panel *ngFor="let subject of taskResultReview?.subjects">
                                                                <mat-expansion-panel-header>
                                                                    {{subject.subjectName}} {{subject.pointsEarned}}/{{subject.pointsTotal}}
                                                                </mat-expansion-panel-header>
                                                                <button class="question-button" (click)="goDown1(question.subjectId*1000+question.orderNum)"
                                                                        *ngFor="let question of subject.questions">
                                                                    <p *ngIf="question.failCount>0 || question.failCount+question.successCount===0"
                                                                       style="color: red!important;">{{question.orderNum}}</p>
                                                                    <p *ngIf="question.failCount==0 && question.successCount>0">{{question.orderNum}}</p>
                                                                </button>
                                                            </mat-expansion-panel>
                                                        </mat-accordion>
                                                    </mat-card>
                                                </div>
                                            </div>

                                            <div *ngIf="!isMobile" class="col-md-12 py-3" id="myDIV">
                                                <div class="row" style="display: flex;">
                                                    <div class="col" *ngFor="let subj of taskResultReview.subjects">
                                                        <mat-card class="sub">
                                                            <mat-card-subtitle class="sub-title"><b style="color: #3d3d3d">{{subj.subjectName}}</b></mat-card-subtitle>
                                                            <h5 class="subBall">{{subj.pointsEarned}}/{{subj.pointsTotal}}</h5>
                                                            <mat-progress-bar mode="determinate"
                                                                              [value]="100*subj.pointsEarned/subj.pointsTotal"></mat-progress-bar>
                                                            <mat-table [dataSource]="subj.statistics" style="width: 100%!important; align-content: start">
                                                                <!-- Тема -->
                                                                <ng-container matColumnDef="topicName" style="width: 90%!important;">
                                                                    <mat-header-cell *matHeaderCellDef>{{'Topic' | translate}}</mat-header-cell>
                                                                    <mat-cell *matCellDef="let element" class="align-left" style="width: 120px;word-break: break-word;" ><i>{{ element.topicName }}</i></mat-cell>
                                                                </ng-container>

                                                                <!-- Количество ошибок -->
                                                                <ng-container matColumnDef="failCount" style="width: 10%!important;">
                                                                    <mat-header-cell *matHeaderCellDef class="align-right" style="display: flex;justify-content: flex-end">{{'Fail' | translate}}</mat-header-cell>
                                                                    <mat-cell *matCellDef="let element" class="align-right" style="display: flex;justify-content: flex-end"><b>{{ element.failCount }}</b></mat-cell>
                                                                </ng-container>

                                                                <!-- Добавьте другие столбцы, если необходимо -->

                                                                <mat-header-row *matHeaderRowDef="['topicName', 'failCount']"></mat-header-row>
                                                                <mat-row *matRowDef="let row; columns: ['topicName', 'failCount'];"></mat-row>
                                                            </mat-table>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="isMobile" class="col-md-12 py-3" id="myD">
                                                <div class="row" style="display: flex;">
                                                    <div class="col" *ngFor="let subj of taskResultReview.subjects">
                                                        <mat-card class="sub">
                                                            <mat-card-subtitle class="sub-title"><b style="color: #3d3d3d">{{subj.subjectName}}</b></mat-card-subtitle>
                                                            <h5 class="subBall">{{subj.pointsEarned}}/{{subj.pointsTotal}}</h5>
                                                            <mat-progress-bar mode="determinate"
                                                                              [value]="100*subj.pointsEarned/subj.pointsTotal"></mat-progress-bar>
                                                            <mat-accordion>
                                                                <mat-expansion-panel>
                                                                    <mat-expansion-panel-header>
                                                                        <mat-panel-title>
                                                                            {{'Analysis' | translate}}
                                                                        </mat-panel-title>
                                                                    </mat-expansion-panel-header>

                                                                    <mat-table [dataSource]="subj.statistics" style="width: 100%!important; align-content: start">
                                                                        <!-- Тема -->
                                                                        <ng-container matColumnDef="topicName" style="width: 90%!important;">
                                                                            <mat-header-cell *matHeaderCellDef>{{'Topic' | translate}}</mat-header-cell>
                                                                            <mat-cell *matCellDef="let element" class="align-left"><i>{{ element.topicName }}</i></mat-cell>
                                                                        </ng-container>

                                                                        <!-- Количество ошибок -->
                                                                        <ng-container matColumnDef="failCount" style="width: 10%!important;">
                                                                            <mat-header-cell *matHeaderCellDef class="align-right">{{'Fail' | translate}}</mat-header-cell>
                                                                            <mat-cell *matCellDef="let element" class="align-right"><b>{{ element.failCount }}</b></mat-cell>
                                                                        </ng-container>

                                                                        <!-- Добавьте другие столбцы, если необходимо -->

                                                                        <mat-header-row *matHeaderRowDef="['topicName', 'failCount']"></mat-header-row>
                                                                        <mat-row *matRowDef="let row; columns: ['topicName', 'failCount'];"></mat-row>>
                                                                    </mat-table>


                                                                </mat-expansion-panel>
                                                            </mat-accordion>
                                                            <!-- Добавьте другие столбцы, если необходимо -->

                                                        </mat-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-card class="block-question">
                                        <div *ngFor="let subject of taskResultReview.subjects" style="margin-bottom: 2rem">
                                            <h2 style="align-content: center; font-weight: bold">{{subject.subjectName}}</h2>
                                            <div *ngFor="let question of subject.questions" class="q-content">
                                                <p id="{{question.subjectId*1000+question.orderNum}}" class="question-no">
                                                    {{'Question' | translate}} - {{question.orderNum}}
                                                </p>
                                                <p class="question" [innerHTML]="question.qmcontent"></p>
                                                <mat-divider *ngIf="question.qmcontent"></mat-divider>
                                                <br *ngIf="question.qmcontent">
                                                <p class="question" [innerHTML]="question.qcontent"></p>
                                                <div class="not-answered" fxLayout="row" fxLayoutAlign="space-around center"
                                                     *ngIf="question.failCount === 0 && question.successCount === 0">
                                                    <img class="notification" src="assets/images/image-gallery/notification.png"> <p>{{'You haven\'t answered this question, but the correct answer to this question is' | translate}}
                                                    :</p>
                                                </div>
                                                <mat-radio-group class="q-radio-group">
                                                    <mat-radio-button class="q-radio-button" disabled=true [ngStyle]="{'border': getColor(choice)}"
                                                                      *ngFor="let choice of question.answers" [value]="choice">
                                                        <label [innerHTML]="choice.content"></label>
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                <div align="end" style="padding-block-end: 10px;">
                                                    <button mat-stroked-button class="app-btn"
                                                            style="margin-right: 10px;"
                                                            *ngIf="question.description !== null">
                                                        {{'Answer explanation' | translate}}
                                                    </button>
                                                </div>
                                                <mat-divider style="padding: 10px;">
                                                </mat-divider>
                                            </div>
                                            <div align="end">
                                            </div>
                                        </div>
                                        <button style="width: 170px; margin:auto; border-radius: 10px; background-color: #3f51b5; padding: 10px 20px; border: none; color: white" class="app-btn fixed-button zaliv circle-button" (click)="goto('mainHeader')">
                                            <i class="fas fa-arrow-up icon-color icon-left"></i>

                                            <!--            <span style="font-weight: bold; color: white; margin: 10px">{{ 'Scroll to top' | translate }}</span>-->
                                        </button>
                                    </mat-card>
                                </div>
                                <div class="col col col-lg-4" style="height: 100%!important;">
                                    <div class="sticked-menu" style="height: 100%!important;overflow-y: hidden!important">
                                        <mat-card class="block-buttons " *ngFor="let subject of taskResultReview?.subjects">
                                            <mat-card-title>{{subject.subjectName}}</mat-card-title>
                                            <button class="question-button" (click)="goDown1(question.subjectId*1000+question.orderNum)"
                                                    *ngFor="let question of subject.questions">
                                                <p *ngIf="question.failCount>0 || question.failCount+question.successCount===0"
                                                   style="color: red!important;">{{question.orderNum}}</p>
                                                <p *ngIf="question.failCount==0 && question.successCount>0">{{question.orderNum}}</p>

                                            </button>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
